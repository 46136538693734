import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueMeta from 'vue-meta';  
  
import VCharts from 'v-charts'
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VCharts)
Vue.use(VueMeta);  


new Vue({
  router,
   mounted () { // 等vue执行mounted生命周期后，表示页面已经挂载完成了，这是再触发自定义事件，告诉插件可以渲染了
 document.dispatchEvent(new Event('compliance-college'))
  },
  render: function (h) { return h(App) }
}).$mount('#app')

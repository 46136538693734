<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
export default {
  name: "app",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      document
        .getElementById("app")
        .scrollIntoView({ block: "start", behavior: "smooth" });
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style lang="scss">
// @import "./styles/input.scss";
@import "./styles/common.scss";
@import "./styles/public.css";
#app {
  // font-family: PingFangTC-Medium, PingFangTC;
  font-family: "PingFang SC";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-weight: normal;
}
</style>

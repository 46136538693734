<style scoped lang="scss">
@import "@/pages/css/about.scss";
</style>
<template>
  <div class="about">
    <div class="about-header">
      <img
        src="https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/banner.png"
        alt=""
      />
      <div class="about-header-box">
        <h1 class="banner-text">一半法律人，一半工程师</h1>
        <div class="banner-tips">
          深耕法律科技领域15年+，用思维与产品陪伴十几万法律人高效工作
        </div>
      </div>
    </div>

    <div class="about-box">
      <div class="about-box-head">
        <img
          src="https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/about-us.png"
          alt=""
          class="about-us"
        />
        <h2 class="about-box-tab-title">关于我们</h2>
      </div>
      <div class="about-box-all">
        <div class="left-content">
          <div class="about-box-title">iLaw 简介</div>
          <div class="about-box-des">北京合规科技有限公司旗下品牌</div>
          <div class="line"></div>
          <div class="brief-introduction">
            总公司为iCourt北京新橙科技有限公司，总部位于北京DayOne法律科技大楼，并在深圳、郑州、海南、武汉等多地设有办公室。团队由800多名员工组成，包括法律专家、技术专家、数据运营官等，共同打造优质的合规数字化体系，为企业员工合规、企业内部、合规法务团队专业水平的提升，积极贡献力量。
          </div>
        </div>
        <div class="right-content">
          <div class="imgs">
            <div class="vision">
              <img
                src="https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/yj.png"
                class=""
                alt=""
              />
              <div class="vision-txt">
                <div class="txt">愿景</div>
                <div class="desc">让法治之光洒满世界的每个角落</div>
              </div>
            </div>
            <div class="mission">
              <img
                src="https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/sm.png"
                alt=""
              />
              <div class="mission-txt">
                <div class="txt">使命</div>
                <div class="desc">科技驱动合规</div>
              </div>
            </div>
            <div class="values">
              <img
                src="https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/jzg.png"
                alt=""
              />
              <div class="values-txt">
                <div class="txt">价值观</div>
                <div class="desc">客户第一、拥抱变化、简单可依赖</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="introduce-txt-one">
        公司成立10年以来，建立了坚实的法律合规技术中台，研发了中国第一个智能化的法律操作系统Alpha，用户超过15w+法律合规专业人士。2018年公司被评为中国大数据100强，是法律行业唯一入选的企业。
      </div>
      <div class="introduce-txt-too">
        在多年的法律合规大数据积累的基础上，公司打造了强大的企业合规产品的研发中台，旨在帮助企业法务部加速数字化建设，助力企业合规经营、行稳致远、科学决策。
      </div>
    </div>

    <div class="about-detail">
      <div class="about-box-detail">
        <div class="about-img-list">
          <img
            :src="img.img"
            alt=""
            v-for="img of aboutImgList"
            :key="img.id"
          />
        </div>
      </div>
      <div class="about-team">
        <div
          class="about-team-card"
          v-for="(item, index) of teamList"
          :key="index"
        >
          <div class="about-team-card-item">
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
        <div class="solution">
          <div class="solution-card" @click="goForm">
            <div class="title">iLaw</div>
            <div class="txt">一站式解决方案</div>
            <img
              src="https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/nav/arrow-right.png"
              alt=""
              class="arrow"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="contact-box">
      <h2 class="about-box-tab-title">联系我们</h2>
      <div class="company-list">
        <div class="company-item" v-for="item of companyList" :key="item.id">
          <img :src="item.img" alt="" />
          <div class="company-title">{{ item.title }}</div>
          <div class="company-location">{{ item.location }}</div>
        </div>
      </div>
    </div>
    <footerCom></footerCom>
    <reservationCom></reservationCom>
  </div>
</template>

<script>
import footerCom from "../components/footerCom/index.vue";
import reservationCom from "../components/reservationCom/index.vue";

export default {
  metaInfo: {
    title: "关于我们",
    meta: [
      {
        name: "keywords",
        content: "iCourt，北京新橙，北京合规科技，风险管理",
      },
    ],
  },
  name: "about",
  components: {
    footerCom,
    reservationCom,
  },
  data() {
    return {
      aboutImgList: [
        {
          id: 1,
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/us2.jpeg",
        },
        {
          id: 2,
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/us3.jpeg",
        },
        {
          id: 3,
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/us4.png",
        },
        {
          id: 4,
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/us5.png",
        },
      ],
      memberList: [
        {
          id: 1,
          title: "创始人",
          detail:
            "我们的创始人团队由深耕法律科技行业十多年的卓越企业家胡清平老师和业界顶尖法律人士，具备丰富的行业经验和对合规业务的独到洞察，致力于引领公司在合规软件服务领域的创新和发展。",
        },
        {
          id: 2,
          title: "合规专家团队",
          detail:
            "我们的合规专家团队汇集了业内顶尖的企业合规专家、企业法务、机构律师，拥有深厚的专业知识和丰富的实践经验，能够为客户提供针对性、创新性的合规咨询和解决方案。",
        },
        {
          id: 3,
          title: "技术专家团队",
          detail:
            "我们的技术专家团队由法律+计算机双学术背景的专业人员组成，拥有丰富的技术实战经验和前沿的技术视野，致力于为客户提供高效、创新的技术解决方案，助力公司产品的持续创新和业务升级。",
        },
      ],
      companyList: [
        {
          id: 1,
          title: "北京｜总部",
          location: "北京市朝阳区高碑店乡西店村49号楼",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/company1.png",
        },
        {
          id: 2,
          title: "深圳",
          location: "广东省深圳市南山区赛西科技大厦",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/company2.png",
        },
        {
          id: 3,
          title: "郑州",
          location: "河南自贸试验区郑州片区(郑东)东风南路绿地中心",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/company3.png",
        },
        {
          id: 4,
          title: "海南",
          location: "海南省陵水黎族自治县尔蔚街iLaw陵水学院",
          img:
            "https://heguixueyuan-prod.oss-cn-beijing.aliyuncs.com/imgs/about/company4.png",
        },
      ],
      teamList: [
        {
          title: "创始人团队",
          desc:
            "iLaw合规创始人团队由深耕法律科技行业十多年的卓越企业家和业界顶尖法律人士组成，具备丰富的行业经验和对合规业务的独到洞察，致力于引领公司在合规软件服务领域不断创新和发展。",
          id: 1,
        },
        {
          title: "合规专家团队",
          desc:
            "iLaw合规专家团队汇集了业内顶尖的企业合规专家、企业法务、机构律师，拥有深厚的专业知识和丰富的实践经验，能够为客户提供针对性、创新性的合规咨询和解决方案。",
          id: 2,
        },
        {
          title: "技术专家团队",
          desc:
            "iLaw合规技术专家团队由法律+计算机双学术背景的专业人员组成，拥有丰富的技术实战经验和前沿的技术视野，致力于为客户提供高效、创新的技术解决方案，助力公司产品的持续创新和业务升级。",
          id: 3,
        },
        // { title: '创始人团队', desc: 'iLaw合规创始人团队由深耕法律科技行业十多年的卓越企业家和业界顶尖法律人士组成，具备丰富的行业经验和对合规业务的独到洞察，致力于引领公司在合规软件服务领域不断创新和发展。', id: 4 },
      ],
    };
  },
  methods: {
    goToAppointment() {
      const url = window.location.origin;
      window.open(`${url}/appointment`);
    },
    goForm() {
      const url = window.location.origin;
      window.open(`${url}/appointment`);
    },
  },
};
</script>

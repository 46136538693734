<template>
  <div>
    <NavCom />
    <router-view />
    <!-- <FooterCom /> -->
  </div>
</template>

<script>
import NavCom from '../components/navCom/index'
// import FooterCom from '../components/footerCom/index'
export default {
  name: 'index',
  components: {
    NavCom,
    // FooterCom
  },
  data() {
    return {
    }
  }
}
</script>